<template>
  <b-form>
    <div class="d-flex flex-row flex-wrap">
      <!-- <div class="col-lg-2 col-12 px-2 mb-2">
        <label class="font-weight-bold ft-s-14" for="headQuarter">ภาค</label>
        <v-select
          id="headQuarter"
          v-model="headQuarter"
          :options="headQuarters"
          :disabled="isClass || disabled"
          :loading="isFetchingHeadQuarter"
        ></v-select>
      </div>

      <div class="col-lg-2 col-12 px-2 mb-2">
        <label class="font-weight-bold ft-s-14" for="division">จังหวัด</label>
        <v-select
          id="division"
          v-model="division"
          :options="divisions"
          :disabled="isClass || disabled"
          :loading="isFetchingDivision"
        ></v-select>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold ft-s-14" for="station">สถานี</label>
        <v-select
          id="station"
          v-model="station"
          :options="stations"
          :disabled="isClass || disabled"
          :loading="isFetchingStation"
        ></v-select>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold ft-s-14" for="school">โรงเรียน</label>
        <v-select
          id="school"
          v-model="school"
          :options="schools"
          :disabled="isClass || disabled"
          :loading="isFetchingSchool"
        ></v-select>
      </div> -->

      <!-- <div class="col-lg-2 col-12 px-2 mb-2">
        <label class="font-weight-bold ft-s-14" for="schoolClass"
          >ชั้นเรียน</label
        >
        <v-select
          id="schoolClass"
          v-model="schoolClass"
          :options="classes"
          :disabled="isClass || disabled"
          :loading="isFetchingSchoolClass"
        ></v-select>
      </div> -->

      <div class="col-lg-3 col-12 px-2 mb-2">
        <label class="font-weight-bold ft-s-14" for="room">ห้องเรียน</label>
        <v-select id="room" v-model="formTeachingDare.room" :options="rooms" :disabled="isClass || disabled"></v-select>
      </div>

      <div class="col-lg-3 col-12 px-2 mb-2">
        <label class="font-weight-bold ft-s-14" for="fiscalYear">ปีการศึกษา</label>
        <v-select id="fiscalYear" v-model="formTeachingDare.fiscal_year"
          :disabled="(editData && editData.id !== undefined) || isClass || disabled" :options="fiscalYears"
          :loading="isFetchingSemester"></v-select>
      </div>

      <div class="col-lg-2 col-12 px-2 mb-2">
        <label class="font-weight-bold ft-s-14" for="term">เทอม</label>
        <v-select id="term" v-model="formTeachingDare.term"
          :disabled="(editData && editData.id !== undefined) || isClass || disabled" :options="terms"
          :loading="isFetchingSemester"></v-select>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold ft-s-14" for="planStudent">จำนวนนักเรียนตามแผน</label>
        <input id="planStudent" class="form-control custom__height" type="number" :min="0"
          :disabled="isClass || disabled"
          v-model.number="formTeachingDare.plan_student" />
      </div>

      <!-- <div v-if="isClass" class="col-lg-2 col-12 px-2 mb-2">
        <label class="font-weight-bold ft-s-14" for="box5"
          >จำนวนนักเรียนจริง</label
        >
        <input
          class="form-control custom__height"
          type="number"
          :min="0"
          :disabled="disabled"
          v-model.number="formTeachingDare.actual_student"
        />
      </div> -->
    </div>
    <slot name="custom-body"></slot>
  </b-form>
</template>

<script>
import vSelect from "vue-select";
import {
  MClass,
  MDivision,
  MHeadquarter,
  MSchool,
  MSemester,
  MStation,
} from "../../models";

// const currentYear = new Date().getFullYear() + 543;

export default {
  props: {
    formData: Object,
    editData: {
      type: Object,
      default: () => { },
    },
    isLesson: {
      type: Boolean,
      default: false,
    },
    isClass: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: "formData",
    event: "change",
  },

  components: {
    "v-select": vSelect,
  },

  data() {
    return {
      isFetchingHeadQuarter: false,
      isFetchingDivision: false,
      isFetchingStation: false,
      isFetchingSchool: false,
      isFetchingSchoolClass: false,
      isFetchingSemester: false,
      headQuarter: null,
      division: null,
      station: null,
      school: null,
      schoolClass: null,
      formTeachingDare: {
        room: this.editData.room || "",
        term: this.editData.term || "",
        year: this.editData.year || "",
        fiscal_year: this.editData.fiscal_year || "",
        plan_student: this.editData.plan_student || null,
        actual_student: this.editData.actual_student || null,
        // mStationId: this.editData.mStationId || null,
        // mSchoolId: this.editData.mSchoolId || null,
        // mClassId: this.editData.mClassId || null,
        director_dares:
          this.editData.director_dares && this.editData.director_dares.length
            ? this.editData.director_dares
            : [
              {
                first_name: "",
                last_name: "",
                id_card: "",
                phone_number: "",
                email: "",
                mPrefixId: null,
                mCardtypeId: null,
              },
            ],
        teacher_dares:
          this.editData.teacher_dares && this.editData.teacher_dares.length
            ? this.editData.teacher_dares
            : [
              // {
              //   first_name: "",
              //   last_name: "",
              //   id_card: "",
              //   phone_number: "",
              //   email: "",
              //   mPrefixId: null,
              //   mCardtypeId: null,
              // },
            ],
        student_dares:
          this.editData.student_dares && this.editData.student_dares.length
            ? this.editData.student_dares
            : [
              // {
              //   first_name: "",
              //   last_name: "",
              //   id_card: "",
              //   phone_number: "",
              //   email: "",
              //   mPrefixId: null,
              //   mCardtypeId: null,
              // },
            ],
        formLesson:
          this.editData.formLesson ||
          [
            // {
            //   lesson_number: "",
            //   lesson_name: "",
            //   date: "",
            //   time: "",
            //   content: "",
            //   note: "",
            //   teaching_actual_imgs: [],
            // },
          ],
      },
    };
  },

  watch: {
    formTeachingDare: {
      deep: true,
      immediate: true,
      handler: "syncData",
    },

    "formTeachingDare.fiscal_year": {
      handler: "onFiscalYearChanged",
    },

    "formTeachingDare.term": {
      handler: "onTermChanged",
    },

    headQuarter: {
      handler: "onHeadQuarterChanged",
    },

    division: {
      handler: "onDivisionChanged",
    },

    station: {
      handler: "onStationChanged",
    },

    school: {
      handler: "onSchoolChanged",
    },

    schoolClass: {
      handler: "onSchoolClassChanged",
    },

    // "formTeachingDare.fiscal_year": {
    //   handler: "onFiscalYearChanged",
    // },
  },

  computed: {
    rooms() {
      const rooms = [];
      const maximumRoom = 20;

      for (let roomNumber = 1; roomNumber <= maximumRoom; roomNumber++) {
        rooms.push(roomNumber);
      }

      return rooms;
    },

    activeSemesters() {
      return MSemester.query().where('isActive', true)
        .orderBy('fiscal_year', 'desc')
        .orderBy('term', 'desc')
        .get();
    },

    defaultSemester() {
      return this.activeSemesters.length
        ? this.activeSemesters[0]
        : null;
    },

    fiscalYears() {
      const { activeSemesters = [] } = this;

      const fiscalYears = activeSemesters.map(({ fiscal_year }) => fiscal_year);

      if (!(this.editData && this.editData.id) && this.defaultSemester) {
        this.$set(this.formTeachingDare, "fiscal_year", fiscalYears[0]);
      }

      return [...new Set(fiscalYears)];
    },

    terms() {
      const { formTeachingDare, activeSemesters = [] } = this;

      let terms = [];

      if (formTeachingDare.fiscal_year) {

        const filteredActiveSemesters = activeSemesters.filter(({ fiscal_year }) => formTeachingDare.fiscal_year == fiscal_year);

        if (filteredActiveSemesters.length) {
          const filteredTerms = filteredActiveSemesters.map(({ term }) => term);

          terms = [...new Set(filteredTerms)];

          if (!(this.editData && this.editData.id) && this.defaultSemester) {
            this.$set(this.formTeachingDare, "term", filteredTerms[0]);
          }
        }
      }
      return terms;
    },

    headQuarters() {
      return MHeadquarter.all().map((record) => ({
        id: record.id,
        label: record.m_headquarters_name,
      }));
    },

    divisions() {
      return this.headQuarter
        ? MDivision.query()
          .where("mHeadquarterId", this.headQuarter.id)
          .get()
          .map((record) => ({
            id: record.id,
            label: record.m_division_name,
          }))
        : [];
    },

    stations() {
      return this.division
        ? MStation.query()
          .where("mDivisionId", this.division.id)
          .get()
          .map((record) => ({
            id: record.id,
            label: record.m_station_name,
          }))
        : [];
    },

    schools() {
      return this.headQuarter && this.division && this.station
        ? MSchool.query()
          .where("mHeadquarterId", this.headQuarter.id)
          .where("mDivisionId", this.division.id)
          .where("mStationId", this.station.id)
          .get()
          .map((record) => ({
            id: record.id,
            label: record.m_school_name,
          }))
        : [];
    },

    classes() {
      return MClass.all().map((record) => ({
        id: record.id,
        label: record.m_class_name,
      }));
    },
  },

  methods: {
    // async init() {
    //   this.isFetchingSchoolClass = true;
    //   this.isFetchingHeadQuarter = true;
    //   try {
    //     await MHeadquarter.api().findAll();
    //     await MClass.api().findAll();

    //     if (this.editData && this.editData.m_school) {
    //       const { mHeadquarterId } = this.editData.m_school;

    //       const mHeadquarter = MHeadquarter.find(mHeadquarterId);

    //       if (mHeadquarter) {
    //         this.headQuarter = {
    //           id: mHeadquarter.id,
    //           label: mHeadquarter.m_headquarters_name,
    //         };
    //       }
    //     }
    //   } catch (error) {
    //     console.error(error);
    //     this.$toast.error("ไม่สามารถดึงข้อมูลภาคได้ กรุณาลองใหม่อีกครั้ง");
    //   } finally {
    //     this.isFetchingSchoolClass = false;
    //     this.isFetchingHeadQuarter = false;
    //   }
    // },

    // async onHeadQuarterChanged(headQuarter) {
    //   this.division = null;

    //   if (headQuarter && headQuarter.id) {
    //     this.isFetchingDivision = true;
    //     try {
    //       await MDivision.api().findAll({
    //         mHeadquarterId: headQuarter.id,
    //       });

    //       if (this.editData && this.editData.m_school) {
    //         const { mDivisionId } = this.editData.m_school;

    //         const mDivision = MDivision.find(mDivisionId);

    //         if (mDivision) {
    //           this.division = {
    //             id: mDivision.id,
    //             label: mDivision.m_division_name,
    //           };
    //         }
    //       }
    //     } catch (error) {
    //       console.error(error);
    //       this.$toast.error(
    //         "ไม่สามารถดึงข้อมูลจังหวัดได้ กรุณาลองใหม่อีกครั้ง"
    //       );
    //     } finally {
    //       this.isFetchingDivision = false;
    //     }
    //   }
    // },

    // async onDivisionChanged(division) {
    //   this.station = null;

    //   if (division && division.id) {
    //     this.isFetchingStation = true;
    //     try {
    //       await MStation.api().findAll({
    //         mDivisionId: division.id,
    //       });

    //       if (this.editData && this.editData.m_school) {
    //         const { mStationId } = this.editData.m_school;

    //         const mStation = MStation.find(mStationId);

    //         if (mStation) {
    //           this.station = {
    //             id: mStation.id,
    //             label: mStation.m_station_name,
    //           };
    //         }
    //       }
    //     } catch (error) {
    //       console.error(error);
    //       this.$toast.error("ไม่สามารถดึงข้อมูลสถานีได้ กรุณาลองใหม่อีกครั้ง");
    //     } finally {
    //       this.isFetchingStation = false;
    //     }
    //   }
    // },

    // async onStationChanged(station = null) {
    //   let id = null;

    //   this.school = null;

    //   if (station && station.id) {
    //     id = station.id;

    //     this.isFetchingSchool = true;

    //     try {
    //       await MSchool.api().findAll({
    //         mStationId: station.id,
    //       });

    //       if (this.editData && this.editData.m_school) {
    //         const { id } = this.editData.m_school;

    //         const mSchool = MSchool.find(id);

    //         if (mSchool) {
    //           this.school = {
    //             id: mSchool.id,
    //             label: mSchool.m_school_name,
    //           };
    //         }
    //       }
    //     } catch (error) {
    //       console.error(error);
    //       this.$toast.error(
    //         "ไม่สามารถดึงข้อมูลโรงเรียนได้ กรุณาลองใหม่อีกครั้ง"
    //       );
    //     } finally {
    //       this.isFetchingSchool = false;
    //     }
    //   }

    //   this.formTeachingDare.mStationId = id;
    // },

    // async onSchoolChanged(school = null) {
    //   let id = null;

    //   this.schoolClass = null;

    //   if (school && school.id) {
    //     id = school.id;
    //   }

    //   this.$set(this.formTeachingDare, "mSchoolId", id);

    //   if (this.editData) {
    //     const { mClassId } = this.editData;

    //     const mClass = MClass.find(mClassId);

    //     if (mClass) {
    //       this.schoolClass = {
    //         id: mClass.id,
    //         label: mClass.m_class_name,
    //       };
    //     }
    //   }
    // },

    // onSchoolClassChanged(schoolClass = null) {
    //   let id = null;

    //   if (schoolClass && schoolClass.id) {
    //     id = schoolClass.id;
    //     // room = schoolClass.label;
    //   }

    //   this.$set(this.formTeachingDare, "mClassId", id);
    //   // this.$set(this.formTeachingDare, "room", room);
    // },

    // onFiscalYearChanged(fiscalYear = "") {
    //   // this.$set(this.formTeachingDare, "year", fiscalYear);
    // },

    async onFiscalYearChanged(selected_year = null) {
      let id = null;

      const { formTeachingDare, activeSemesters = [] } = this;

      if (formTeachingDare.term && selected_year) {
        const findSemesters = activeSemesters.find(({ fiscal_year, term }) =>
          formTeachingDare.term == term &&
          selected_year == fiscal_year
        );

        if (findSemesters?.id !== undefined) id = findSemesters.id
      }

      if (id !== undefined && id !== null)
        this.$set(this.formTeachingDare, "mSemesterId", id);
    },

    async onTermChanged(selected_term = null) {
      let id = null;

      const { formTeachingDare, activeSemesters = [] } = this;

      if (formTeachingDare.fiscal_year && selected_term) {
        const findSemesters = activeSemesters.find(({ fiscal_year, term }) =>
          formTeachingDare.fiscal_year == fiscal_year &&
          selected_term == term
        );

        if (findSemesters?.id !== undefined) id = findSemesters.id
      }

      if (id !== undefined && id !== null)
        this.$set(this.formTeachingDare, "mSemesterId", id);
    },

    async fetchSemesters() {
      this.isFetchingSemester = true;

      try {
        await MSemester.api().findAll();
      } catch (error) {
        this.$toast.error("ไม่สามารถดึงข้อมูลปีการศึกษาได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isFetchingSemester = false;
      }

      if (!(this.editData && this.editData.id) && this.defaultSemester) {
        const { fiscal_year, term } = this.defaultSemester;

        this.$set(this.formTeachingDare, "fiscal_year", fiscal_year);
        this.$set(this.formTeachingDare, "year", fiscal_year);
        this.$set(this.formTeachingDare, "term", term);
      }
    },

    syncData(v) {
      this.$emit("change", v);
    },
  },

  mounted() {
    // this.init();
    this.fetchSemesters();
  },
};
</script>
